.detail-page {
    background-color: $white;
    height: 100vh;
    .detail-header {
        position: relative;
        background-color: $primary;
        height: 20vh;
        padding: 20px;
        .user {
            display: flex;
            font-size: 22px;
            position: absolute;
            bottom: -80px;
            right: 50%;
            left: 50%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                margin-bottom: 10px;
            }
        }
    }
    .detail-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $white;
        margin-top: 100px;
        .attributes {
            display: flex;
            flex-direction: row;
            .attribute {
                margin: 10px 20px;
                border: 1px solid $primary;
                padding: 5px 10px;
                border-radius: 8px;
            }
        }
        .repo-detail {
            display: flex;
            flex-direction: column;
            font-size: 18px;
            .title {
                font-weight: 600;
            }
            .short-description {
                font-weight: 300;
                font-style: italic;
            }
        }
    }
}
