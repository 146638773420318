.header {
    position: relative;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;

    color: $white;
    font-size: 32px;
    background-color: $primary;
}
