.repo-card {
    width: 30%;
    margin: 10px 10px;
    padding: 10px 10px;
    color: $primary-dark;
    background-color: $white;
    border-radius: 10px;
    .card-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .user {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 5px;
            }
        }
        .attributes {
            display: flex;
            flex-direction: row;
            align-items: center;
            .attribute {
                margin: 0 5px;
            }
        }
    }
    .title {
        font-size: 22px;
        font-weight: 600;
        margin: 10px 0;
    }
}
