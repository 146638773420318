.search {
    position: absolute;
    bottom: -20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    button {
        border: none;
        outline: none;
        padding: 8px 10px;
        border-radius: 12px 0 0 12px;
        background-color: white;
    }
    select {
        width: 5%;
        padding: 9.3px 5px;
        border: none;
        outline: none;
        border-radius: 0;
        color: $primary-dark;
        // background-color: $white;
    }
    input {
        width: 30%;
        padding: 10.3px 20px;
        border: none;
        outline: none;
        border-radius: 0 12px 12px 0;
        color: $primary-dark;
        // background-color: $white;
    }
}
